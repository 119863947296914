export default class ShainExp {
  incode: string;
  eatMeal: string;
  standardWorkTime: number;
  defaultDistance: number;
  weekWorkStart: string;
  paymentDayKbn: string;
  carType: string;
  qualification: string;
  careUnitPrice: number;
  careExtUnitPrice: number;
  careMidnightUnitPrice: number;
  disabilityUnitPrice: number;
  disabilityExtUnitPrice: number;
  disabilityMidnightUnitPrice: number;
  otherUnitPrice: number;
  otherExtUnitPrice: number;
  otherMidnightUnitPrice: number;
  hideShift: string;
  hideShiftStartDate: string;
  HideShiftStartDateStr: string;
  fukushiCarKbn: string;
  fukushiDistance: number;
  flexflag: string;
  hidePayday: string;
  //dayOver: string;
  subDetails: Array<{
    affiliationId: string;
    amount1: string;
    amount2: string;
    amount3: string;
    amount4: string;
    amount5: string;
    amount6: string;
    //subSpecialCommit: string;
  }>;
  constructor(
    incode = "",
    eatMeal = "",
    standardWorkTime = 0,
    defaultDistance = 0,
    weekWorkStart = "",
    paymentDayKbn = "",
    carType = "",
    qualification = "",
    careUnitPrice = 0,
    careExtUnitPrice = 0,
    careMidnightUnitPrice = 0,
    disabilityUnitPrice = 0,
    disabilityExtUnitPrice = 0,
    disabilityMidnightUnitPrice = 0,
    otherUnitPrice = 0,
    otherExtUnitPrice = 0,
    otherMidnightUnitPrice = 0,
    hideShift = "",
    hideShiftStartDate = "",
    HideShiftStartDateStr = "",
    fukushiCarKbn = "",
    fukushiDistance = 0,
    flexflag = "",
    hidePayday = "",
    //dayOver = "",
    subDetails = []
  ) {
    this.incode = incode;
    this.eatMeal = eatMeal;
    this.standardWorkTime = standardWorkTime;
    this.defaultDistance = defaultDistance;
    this.weekWorkStart = weekWorkStart;
    this.paymentDayKbn = paymentDayKbn;
    this.carType = carType;
    this.qualification = qualification;
    this.careUnitPrice = careUnitPrice;
    this.careExtUnitPrice = careExtUnitPrice;
    this.careMidnightUnitPrice = careMidnightUnitPrice;
    this.disabilityUnitPrice = disabilityUnitPrice;
    this.disabilityExtUnitPrice = disabilityExtUnitPrice;
    this.disabilityMidnightUnitPrice = disabilityMidnightUnitPrice;
    this.otherUnitPrice = otherUnitPrice;
    this.otherExtUnitPrice = otherExtUnitPrice;
    this.otherMidnightUnitPrice = otherMidnightUnitPrice;
    this.hideShift = hideShift;
    this.hideShiftStartDate = hideShiftStartDate;
    this.HideShiftStartDateStr = HideShiftStartDateStr;
    this.fukushiCarKbn = fukushiCarKbn;
    this.fukushiDistance = fukushiDistance;
    this.flexflag = flexflag;
    this.hidePayday = hidePayday;
    //this.dayOver = dayOver;
    this.subDetails = subDetails;
  }
}
